import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "./Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
// import LogoIcon from "../assets/svg/Logo";
import LogoIcon from "../../assets/images/INSYNCONLINE_6NOV24-no-bg.png";
// import LogoIcon from "../../assets/images/logo-darkcp.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { NavLink } from "react-router-dom"; 
import "../../style/slider.css";

const TopNavbar = () => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const openHandler = () => {
    window.open(
      "https://hr.theonlineapps.com/newInsync/auth/signin-2",
      "_blank"
    );
  };

  return (
    <>
      <div className="col-sm-6 display">
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Wrapper
          className="flexCenter animate whiteBg display"
          style={y > 100 ? { height: "60px" } : { height: "80px" }}
        >
          <NavInner className="container flexSpaceCenter">
            <Link className="pointer flexNullCenter" to="home" smooth={true}>
              {/* <LogoIcon />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              fanatic
            </h1> */}
              <img
                id="main-logo"
                src={LogoIcon}
                alt=""
                height={80}
                width={100}
                className="logo"
              />
            </Link>
            <BurderWrapper
              className="pointer"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="flexNullCenter display">
              <li className="semiBold display pointer">
                <NavLink
                  // id="menu-link"
                  className="nav-link"
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Home
                </NavLink>
              </li>
              {/* <li className="semiBold display pointer">
                <NavLink
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Services
                </NavLink>
              </li> */}
              <li className="semiBold display pointer">
                <NavLink
                  className="nav-link"
                  // className="text-burgundy"
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="/contact"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Contact us
                </NavLink>
              </li>
            </UlWrapper>

            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer">
                {/* <Link
                  activeClass="active"
                  style={{ padding: "10px 30px 10px 0" }}
                  to="#"
                  onClick={() => openHandler()}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Log in
                </Link> */}
                {/* <Link
                  activeClass="active"
                  className="rounded-pill"
                  style={{ padding: "10px 20px 10px" ,color:"White", backgroundColor:"rgb(0, 0, 255)"}}
                  to="#"
                  onClick={() => openHandler()}
                  spy={true}
                  smooth={true}
                  offset={-80}
                > Log in
                </Link> */}
              </li>
              {/* <li className="semiBold font15 pointer flexCenter">
            {/* <NavLink to="/">
            <button variant="outline-primary" className="btn btn-primary hoover" >
              Get Started
            </button>
            </NavLink>
             */}
              {/* <NavLink
                variant="outline"
                activeClass="active"
                className="radius8 lightBg"
                style={{ padding: "10px 15px" }}
                to="#"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Get Started
              </NavLink>
            </li> */}
            </UlWrapperRight>
          </NavInner>
        </Wrapper>
      </div>
    </>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

export default TopNavbar;
