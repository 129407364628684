import React, { useState } from 'react'

import "../components/accordion.css"

const Accordion = ({title, details}) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <div className='py-1'>
            <div className=' bg-blue-100 rounded-pill py-2 px-3'>
                <button onClick={() => setAccordionOpen(!accordionOpen)} style={{position: "center", fontSize:"14px"}} className='flex justify-between w-full' >
                    <span>{title}</span>
                    {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
                    <svg 
                        className="fill-gray-400 shrink-0 ml-8 plus"
                        style={{position:"center"}}
                        width="27"
                        height="27"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                         
                        <rect
                            y="4"
                            width="26"
                            height="1"
                            rx="1"
                            className={`transform origin-center transition duration-200 ease-out plus ${accordionOpen && "!rotate-180"
                                }`}
                        />
                        <rect
                            y="4"
                            width="20"
                            height="1"
                            rx="1"
                            className={`transform origin-center rotate-90 transition duration-200 ease-out plus ${accordionOpen && "!rotate-180"
                                }`}
                        />
                    </svg>

                </button>
            </div>
            <div
                className={`px-3 grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm 
                ${accordionOpen ? 'grid-rows-[1fr] opacity-100'
                        : 'grid-rows-[0fr] opacity-0'
                    }`}>
                <div className='overflow-hidden'>{details}</div>
            </div>
        </div>
        
    )
}

export default Accordion